"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.italicconverter = void 0;
var italic4unity2html = {
    pattern: new RegExp("<i>(.*?)<\/i>"),
    replace: function (match, p1) {
        return "<em>" + p1 + "</em>";
    },
};
var italic4html2unity = {
    pattern: new RegExp("<em>(.*?)<\/em>"),
    replace: function (match, p1) {
        return "<i>" + p1 + "</i>";
    },
};
exports.italicconverter = {
    html2unity: italic4html2unity,
    unity2html: italic4unity2html,
};
