"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontsizeconverter = void 0;
var decReg = new RegExp("[1-9]{1}[0-9]*");
var fontsize4unity2html = {
    pattern: new RegExp("<size=([^>]*)>(.*?)<\/size>"),
    replace: function (match, p1, p2) {
        if (!p1.match(decReg)) {
            throw new Error("error font size : " + match);
        }
        return "<span style=\"font-size: " + p1 + "px\">" + p2 + "</span>";
    },
};
var fontsize4html2unity = {
    pattern: new RegExp("<span style=\"font-size: *([^>\"]*)px\">(.*?(?!<span).*?)<\/span>"),
    replace: function (match, p1, p2) {
        if (!p1.match(decReg)) {
            throw new Error("error font size : " + match);
        }
        return "<size=" + p1 + ">" + p2 + "</size>";
    },
};
exports.fontsizeconverter = {
    html2unity: fontsize4html2unity,
    unity2html: fontsize4unity2html,
};
