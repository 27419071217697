"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ParagraphConverter = /** @class */ (function () {
    function ParagraphConverter() {
    }
    ParagraphConverter.prototype.html2unity = function (input) {
        return this.convert(input, "<p>(.*?)<\/p>", this.html2unityfill, "\n");
    };
    ParagraphConverter.prototype.unity2html = function (input) {
        return this.convert(input, "(.*)", this.unity2htmlfill, "");
    };
    ParagraphConverter.prototype.convert = function (input, pattern, fill, separator) {
        var matcharray = pattern !== "(.*)" ? input.match(new RegExp(pattern, "g")) : input.split("\n");
        if (!matcharray) {
            throw new Error("error no paragraph in html input " + input);
        }
        var output = matcharray.map(function (item) {
            var regexp = new RegExp(pattern);
            var regExpMatchArray = item.match(regexp);
            if (!regExpMatchArray) {
                throw new Error("error invalid paragraph in " + item);
            }
            return fill(regExpMatchArray);
        }).join(separator);
        return output;
    };
    ParagraphConverter.prototype.html2unityfill = function (r) {
        var paragraph = r[1];
        return "" + paragraph;
    };
    ParagraphConverter.prototype.unity2htmlfill = function (r) {
        var paragraph = r[1];
        return "<p>" + paragraph + "</p>";
    };
    return ParagraphConverter;
}());
exports.default = ParagraphConverter;
