"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bold_1 = require("./bold");
var italic_1 = require("./italic");
var LinearConverter = /** @class */ (function () {
    function LinearConverter() {
        this.converters = [
            bold_1.boldconverter,
            italic_1.italicconverter,
        ];
    }
    LinearConverter.prototype.html2unity = function (input) {
        return this.convert(input, function (c) { return c.html2unity; });
    };
    LinearConverter.prototype.unity2html = function (input) {
        return this.convert(input, function (c) { return c.unity2html; });
    };
    LinearConverter.prototype.convert = function (input, parse) {
        this.converters.forEach(function (converter) {
            var parser = parse(converter);
            while (input.match(parser.pattern)) {
                input = input.replace(parser.pattern, parser.replace);
            }
        });
        return input;
    };
    return LinearConverter;
}());
exports.default = LinearConverter;
