"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fontsize_1 = require("./fontsize");
var textcolor_1 = require("./textcolor");
var NestedConverter = /** @class */ (function () {
    function NestedConverter() {
        this.converters = [
            fontsize_1.fontsizeconverter,
            textcolor_1.textcolorconverter,
        ];
    }
    NestedConverter.prototype.html2unity = function (input) {
        return this.convert(input, function (c) { return c.html2unity; });
    };
    NestedConverter.prototype.unity2html = function (input) {
        return this.convert(input, function (c) { return c.unity2html; });
    };
    NestedConverter.prototype.convert = function (input, parse) {
        var _loop_1 = function () {
            var ismatch = false;
            this_1.converters.forEach(function (converter) {
                var parser = parse(converter);
                while (input.match(parser.pattern)) {
                    input = input.replace(parser.pattern, parser.replace);
                    ismatch = true;
                }
            });
            if (ismatch === false) {
                return "break";
            }
        };
        var this_1 = this;
        while (true) {
            var state_1 = _loop_1();
            if (state_1 === "break")
                break;
        }
        return input;
    };
    return NestedConverter;
}());
exports.default = NestedConverter;
