"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boldconverter = void 0;
var bold4unity2html = {
    pattern: new RegExp("<b>(.*?)<\/b>"),
    replace: function (match, p1) {
        return "<strong>" + p1 + "</strong>";
    },
};
var bold4html2unity = {
    pattern: new RegExp("<strong>(.*?)<\/strong>"),
    replace: function (match, p1) {
        return "<b>" + p1 + "</b>";
    },
};
exports.boldconverter = {
    html2unity: bold4html2unity,
    unity2html: bold4unity2html,
};
