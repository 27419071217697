<template>
  <div id="app">
    <header class="text-uppercase" style="background-color: #000; color: #fff;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-8">
            <span>
              <img
                  src="https://unity3d.com/profiles/unity3d/themes/unity/images/pages/branding_trademarks/unity-tab-square-black.png"
                  width="50px"/>
            </span>
            <span id="navbarResponsive">
              Online Unity Rich Text Preview
            </span>
          </div>
        </div>
      </div>
    </header>
    <section id="body" class="container">
      <div class="row justify-content-center">
        <div class="col-8">
          <div class="container">
            <form>
              <div class="form-group">
                <div><label for="input-text"><strong>Rich text original</strong></label></div>
                <div>Example:
                  <code
                      id="example"
                      class="highlighter-rouge ms-1"
                      @click.prevent="useExample"
                  >We <span class="nt">&lt;b&gt;</span>are<span
                      class="nt">&lt;/b&gt;</span> <span class="nt">&lt;color=green&gt;</span>green<span
                      class="nt">&lt;/color&gt;</span> with <span class="nt">size=10&gt;</span>envy<span
                      class="nt">&lt;/size&gt;</span> <span class="nt">&lt;color=#80000055&gt;</span>#80000055<span
                      class="nt">&lt;/color&gt;</span>
                  </code>
                </div>
                <b-tooltip target="example" title="Try example"></b-tooltip>
                <textarea
                    id="input-text"
                    class="container-fluid"
                    v-model.lazy="richText"
                    v-debounce="1000"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="preview"><strong>Preview</strong></label>
                <div class="alert alert-warning" role="alert" v-if="error">
                  {{ error }}
                </div>
                <div
                    id="preview"
                    class="container-fluid"
                    v-html="richTextConverted"
                ></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-8">
              <span class="love">Handcrafted with <span style="color: #e25555;">&#9829;</span> by <a href="https://github.com/speto" target="_blank">Speto</a></span>
              <div class="float-right">
              <a href="https://docs.unity3d.com/Packages/com.unity.ugui@1.0/manual/StyledText.html" target="_blank">docs.unity3d.com/StyledText</a> |
              based on <a href="https://github.com/AWaterColorPen/unity-rich-text-converter/" target="_blank">AWaterColorPen/unity-rich-text-converter</a>
              </div>
            </div>
          </div>
        </div>
    </footer>
  </div>
</template>

<script>

import Converter from "unity-rich-text-converter";
import debounce from "v-debounce";

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      richText: '',
      error: ''
    };
  },
  computed: {
    richTextConverted: function () {
      return this.convert()
    }
  },
  methods: {
    convert() {
      const converter = new Converter();
      try {
        return converter.unity2html(this.richText);
      } catch (e) {
        this.error = e
        return ''
      }
    },
    useExample() {
      return this.richText = 'We <b>are</b> <color=green>green</color> with <size=10>envy</size> <color=#80000055>#80000055</color>';
    }
  },
  directives: {
    debounce
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#body {
  margin-top: 60px;
}

textarea {
  height: 200px;
}

.nt {
  color: #2f6f9f;
}

#example:hover {
  text-decoration: underline;
  cursor: pointer;
}

#example {
  /*background-color: #f7f7f9;*/
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 60%;
}
.footer {
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #000;
  font-size: 80%;
}
.footer a {
  color: #fff;
}
</style>
